.footer-container{
  display:flex;  
  width:"90%";
  margin:0 auto;
}

.footer {
  display:block;
  color:#fff;
  user-select: none;
}





