.container{
  width: 90%;
  margin: 0 auto;
 
}

section {
  min-height:50vh;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.contact{
  display: flex;

  margin-bottom: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.company-name {
  font-weight: 500;
}


.column-center{
  display: flex;
  flex-direction: column;
  flex:1;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex-shrink: 0;
}

.column-center img{
  max-width: 100%;
  max-height: 100%;
}

.title {
  font-size: 50px;
  margin-top:10px;
  margin-bottom:10px;
  font-weight: 500;

}

.content {
  width: 50vh;
  font-size: 18px;
  margin-top:15px;
  margin-bottom:0px;
  text-align: left;
  color:#333;
}


.form-row{
 margin-bottom:15px;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

input {
  display: flex;
  width:40vh;
  height:40px;
  margin-left:15px;
  border-radius:10px;

  padding-left: 10px;
}

textarea {
  width:40vh;
  margin-left:15px;
  border-radius:10px;
  resize: none;

  margin-top:15px;

  padding-top:10px;
  padding-left:10px;

  vertical-align: middle;
}

button{
  display: flex;
  width:10vh;
  height:40px;
  border-radius:10px;
 
  justify-content: center;
  align-items: center;
}

.divider{
  display: none;
}

@media only screen and (max-width: 768px) and (max-width: 1024px){
  /* For mobile phones: */
  section {
    display:flex;
    flex-direction: column;
    align-items: center;
    height: max-content;

   margin-bottom: 50px;
  }

  .column-center img{
    display: block;
    max-width: 100%;
    max-height: 100%;

    margin-top:15px;

  }

  .content{
    width: 100%;
  }

  .form-row{
    flex-direction: column;
  }

  input {
    margin-top: 5px;
    margin-left:0px;
    width:95%;
   
  }

  textarea {
      margin-left:0px;
      width:95%;
  }

  button{
    float:right;
  }

  .divider{
    /* display: block;
    width: 100%;
   
    border: solid 1px #333;
    margin-top:50px;
    margin-bottom: 50px; */
  }
}

@media (min-width:801px) and (max-width:1440px) {
  section{
    height: auto;
    margin-bottom: 50px;
  }

  .column-center img{
    display: block;
    max-width: 100%;
    max-height: 100%;

    margin-left:10px;
    margin-right:10px;
  }

  

  .content{
    width: 90%;    
  }
}