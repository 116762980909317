.nav-header {
  list-style: none; 
  padding:0;
  margin:0;
  user-select: none;
  overflow: hidden;
}

.nav-container{
  display:flex;
 
  background:#333;

  align-items: flex-start;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
}

nav{
  display:flex; 
  margin: 0 auto;
  align-self: flex-start;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}


li{
  float: left;
}

.link {
  display:block;
 
  padding: 15px;
  color:#fff;
  text-align: center;
  cursor: pointer;
}

.link:hover {
  color:rgb(204, 221, 221);
}




